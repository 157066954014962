import { api } from "../../../../../services/api";

const CurrentInvestmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateCurrentInvestment: builder.mutation({
        query: ({projectName,investment}) => ({
          url: `${projectName}/update-current-investments/`,
          method: "PUT",
          body: JSON.stringify(investment),
          headers: {
            "Content-Type": "application/json",
          },
        }),
        invalidatesTags: ["Investment"],
      }),
      fetchBooks: builder.query({
        query: (projectName) => `${projectName}/get-books/`,
      }),
      fetchCurrentInvestments: builder.query({
        query: (projectName) => `${projectName}/get-current-investments/`,
        providesTags: ["Investment"],
      }),
  }),
  overrideExisting: false,
});

export const {
  useUpdateCurrentInvestmentMutation,
  useFetchBooksQuery,
  useFetchCurrenciesQuery,
  useFetchCurrentInvestmentsQuery,
} = CurrentInvestmentsApi;
