import { api } from '../../services/api'

const HomePageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPublicProjects: build.query({
        query: (projectName) => `${projectName}/public-projects/`,
      }),
  }),
  overrideExisting: false,
})

export const {  useGetPublicProjectsQuery } = HomePageApi