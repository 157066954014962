import React, { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Box,
  Card,
  CardContent,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";

const LseFetchButtonGroup = ({
  fetchData,
  tradingviewTrigger,
  instrumentTrigger,
  noMetaDataTrigger,
  weeklyTrigger,
  monthlyTrigger,
  isLoading,
  msgOpen,
  msgAlert,
  handleMsgClose,
}) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Display the message when msgAlert is updated
  useEffect(() => {
    if (msgAlert.message) {
      console.log(`Received message: ${msgAlert.message}`);
    }
  }, [msgAlert]);

  console.log("msgAlert: ", msgAlert)
  return (
    <Card sx={{ margin: 0, padding: 0, minWidth: "300px" }}>
      <CardContent>
        <Box
          sx={{
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button onClick={() => fetchData(tradingviewTrigger)}>
              Fetch TradingView
            </Button>
            <Button onClick={() => fetchData(instrumentTrigger)}>
              Fetch Instruments
            </Button>
            <Button onClick={() => fetchData(noMetaDataTrigger)}>
              Fetch Need Objectives
            </Button>
          </ButtonGroup>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button onClick={() => fetchData(weeklyTrigger)}>
              Update Weekly Volumes
            </Button>
            <Button onClick={() => fetchData(monthlyTrigger)}>
              Update Monthly Volumes
            </Button>
          </ButtonGroup>
          {isLoading && (
            <Snackbar
              open={isLoading}
              autoHideDuration={4000}
              onClose={handleMsgClose}
            >
              <Alert
                onClose={handleMsgClose}
                severity={msgAlert.type}
                sx={{ width: "100%" }}
              >
                Loading....
              </Alert>
            </Snackbar>
          )}
          {msgOpen && (
            <Snackbar
              open={msgOpen}
              autoHideDuration={4000}
              onClose={handleMsgClose}
            >
              <Alert
                onClose={handleMsgClose}
                severity={msgAlert.type}
                sx={{ width: "100%" }}
              >
                {msgAlert.message || "An error occurred."}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

LseFetchButtonGroup.propTypes = {
  fetchData: PropTypes.func.isRequired,
  tradingviewTrigger: PropTypes.func.isRequired,
  instrumentTrigger: PropTypes.func.isRequired,
  noMetaDataTrigger: PropTypes.func.isRequired,
  weeklyTrigger: PropTypes.func.isRequired,
  monthlyTrigger: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  msgOpen: PropTypes.bool.isRequired,
  msgAlert: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  handleMsgClose: PropTypes.func.isRequired,
};

export default LseFetchButtonGroup;

