import React from 'react';
import { Container, CssBaseline, Typography, Box, Button, Grid, AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

function HeroSection() {
  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
          Strategic app modernization
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Digital transformation is essential in today’s competitive marketplace, but to be successful it needs a guiding strategy
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={{ m: 1 }}>Download the full report</Button>
          <Button variant="outlined" sx={{ m: 1 }}>Subscribe to CX topic updates</Button>
        </Box>
      </Container>
    </Box>
  );
}

function ContentSection({ title, children }) {
  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      <Typography variant="h4" component="h2" gutterBottom>{title}</Typography>
      <Typography variant="body1" paragraph>{children}</Typography>
    </Container>
  );
}

function TestPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>Consulting Services</Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
      <HeroSection />
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={0}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab label="Overview" />
              <Tab label="Strategy" />
              <Tab label="Continuity" />
              <Tab label="Vision" />
              <Tab label="Outcomes" />
              <Tab label="Culture" />
              <Tab label="Download the full report now" />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9}>
            <ContentSection title="Overview">
              Application modernization should be a key part of that strategy, and an experienced partner can guide you through the four steps to success. App modernization is always going to be highly customized work, but it’s work worth taking seriously — the benefits are enormous. A strategic approach means you can see the whole picture and build value in phases.
            </ContentSection>
            <ContentSection title="Strategy">
              Digital transformation is essential, but it requires a strategy.
            </ContentSection>
            {/* Add more ContentSections for other tabs */}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default TestPage;
