import React from 'react';
import { Box, Avatar, Typography, Grid } from '@mui/material';
import PuzzleIcon from '@mui/icons-material/Extension';  // Example icons, replace with your own
import EcosystemIcon from '@mui/icons-material/Public';
import GovernanceIcon from '@mui/icons-material/Gavel';
import IntegrationIcon from '@mui/icons-material/Settings';
import DataScienceIcon from '@mui/icons-material/ShowChart';
import CultureIcon from '@mui/icons-material/People';

const items = [
  { label: 'DATA & ANALYTICS STRATEGY', icon: <PuzzleIcon /> },
  { label: 'PLATFORMS & ECOSYSTEMS', icon: <EcosystemIcon /> },
  { label: 'MANAGEMENT & GOVERNANCE', icon: <GovernanceIcon /> },
  { label: 'DATA INTEGRATION & ENGINEERING', icon: <IntegrationIcon /> },
  { label: 'ANALYTICS & DATA SCIENCE', icon: <DataScienceIcon /> },
  { label: 'DATA CULTURE', icon: <CultureIcon /> },
];

const HorizontalGraphic = () => {
  return (
    <Box sx={{ py: 5 }}>
      <Grid container spacing={2} justifyContent="center">
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: '#070F2B',
                color:'#f5f5f5',
                borderRadius: '25px',
                p: 2,
                mx: 1,
                boxShadow: 3,
                height: '100%', // Ensure full height for items
              }}
            >
              <Avatar sx={{ mb: 1 }}>{item.icon}</Avatar>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HorizontalGraphic;
