import React from "react";
import { Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NinetyNinePctImage from "../../assets/images/99pct.jpg";
import HoC from "../../assets/images/Hoc.png";
// Define a sample component structure
const sampleComponents = [
  {
    title: "99%",
    description: "A full stack website that I built for the 99%.org.",
    imageUrl: NinetyNinePctImage,
    path:'/99-Home'
  },

  {
    title: "Tell me about my MP.",
    description:
      "This site allows users to easily get information from the House of Commons Register of Public Interests.",
    imageUrl: HoC,
    path:'/My-MP'
  },
];

const PublicProjects = () => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={2} sx={{ padding: 4 }}>
      {sampleComponents.map((component, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Card
            onClick={() => navigate(component.path)}
            sx={{ cursor: "pointer" }}
          >
            <CardMedia
              sx={{ height: 140, width: "100%", objectFit: "cover" }}
              component="img"
              image={component.imageUrl}
              alt={component.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {component.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {component.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PublicProjects;
