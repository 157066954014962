// src/SkillChips.js

import React from 'react';
import { Box, Chip, Avatar, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as ReactLogo } from '../../assets/images/react-2.svg';
import { ReactComponent as NumpyLogo } from '../../assets/images/numpy-1.svg';
import { ReactComponent as PythonLogo } from '../../assets/images/python-5.svg';
import { ReactComponent as AWSLogo } from '../../assets/images/aws-2.svg';
import { ReactComponent as DjangoLogo } from '../../assets/images/django.svg';
import { ReactComponent as FlaskLogo } from '../../assets/images/flask.svg';
import { ReactComponent as PandasLogo } from '../../assets/images/pandas.svg';
import { ReactComponent as ChatGPTLogo } from '../../assets/images/chatgpt-6.svg';
import { ReactComponent as Docker } from '../../assets/images/docker-4.svg';
import { ReactComponent as MaterialUI } from '../../assets/images/material-ui-1.svg';
import { ReactComponent as JavaScript } from '../../assets/images/logo-javascript.svg';
import { ReactComponent as FastAPI } from '../../assets/images/fastapi-1.svg';
import { ReactComponent as DjangoRF } from '../../assets/images/Django REST.svg';
import { ReactComponent as GitHub } from '../../assets/images/GitHub.svg';
import { ReactComponent as YAML } from '../../assets/images/YAML.svg';
import { ReactComponent as NGINX } from '../../assets/images/NGINX.svg';
import { ReactComponent as Gunicorn } from '../../assets/images/gunicorn.svg';
import { ReactComponent as CSS3 } from '../../assets/images/CSS3.svg';
import { ReactComponent as HTML5 } from '../../assets/images/HTML5.svg';
import { ReactComponent as JSON } from '../../assets/images/JSON.svg';
import { ReactComponent as Jupyter } from '../../assets/images/Jupyter.svg';
import { ReactComponent as Linux } from '../../assets/images/Linux.svg';
import { ReactComponent as Matplotlib } from '../../assets/images/Matplotlib.svg';
import { ReactComponent as MySQL } from '../../assets/images/MySQL.svg';
import { ReactComponent as PostgreSQL } from '../../assets/images/PostgresSQL.svg';
import { ReactComponent as Git } from '../../assets/images/Git.svg';
import { ReactComponent as Postman } from '../../assets/images/Postman.svg';
import { ReactComponent as ReactBootstrap } from '../../assets/images/React Bootstrap.svg';
import { ReactComponent as Redux } from '../../assets/images/Redux.svg';
import { ReactComponent as Selenium } from '../../assets/images/Selenium.svg';
import { ReactComponent as SQLAlchemy } from '../../assets/images/SQLAlchemy.svg';
import { ReactComponent as Debian } from '../../assets/images/Debian.svg';
import { ReactComponent as Bloomberg } from '../../assets/images/bloomberg-1.svg';
import { ReactComponent as Bootstrap } from '../../assets/images/Bootstrap.svg';
import { ReactComponent as D3 } from '../../assets/images/D3.js.svg';

const skills = [
  { label: 'React', logo: <ReactLogo /> },
  { label: 'JavaScript', logo: <JavaScript /> },
  { label: 'Python', logo: <PythonLogo /> },
  { label: 'Django', logo: <DjangoLogo /> },
  { label: 'Django Rest Framework', logo: <DjangoRF /> },
  { label: 'Flask', logo: <FlaskLogo /> },
  { label: 'PostgreSQL', logo: <PostgreSQL /> },
  { label: 'MySQL', logo: <MySQL /> },
  { label: 'SQLAlchemy', logo: <SQLAlchemy /> },
  { label: 'Postman', logo: <Postman /> },
  { label: 'Amazon Web Services', logo: <AWSLogo /> },
  { label: 'Numpy', logo: <NumpyLogo /> },
  { label: 'Pandas', logo: <PandasLogo /> },
  { label: 'Matplotlib', logo: <Matplotlib /> },
  { label: 'ChatGPT', logo: <ChatGPTLogo /> },
  { label: 'Bloomberg', logo: <Bloomberg /> },
  { label: 'Docker', logo: <Docker /> },
  { label: 'MaterialUI', logo: <MaterialUI /> },
  { label: 'React Bootstrap', logo: <ReactBootstrap /> },
  { label: 'Bootstrap', logo: <Bootstrap /> },
  { label: 'D3', logo: <D3 /> },
  { label: 'FastAPI', logo: <FastAPI /> },
  { label: 'Redux', logo: <Redux /> },
  { label: 'Github', logo: <GitHub /> },
  { label: 'Git', logo: <Git /> },
  { label: 'JSON', logo: <JSON /> },
  { label: 'YAML', logo: <YAML /> },
  { label: 'Selenium', logo: <Selenium /> },
  { label: 'NGINX', logo: <NGINX /> },
  { label: 'Gunicorn', logo: <Gunicorn /> },
  { label: 'CSS3', logo: <CSS3 /> },
  { label: 'HTML5', logo: <HTML5 /> },
  { label: 'Jupyter', logo: <Jupyter /> },
  { label: 'Linux', logo: <Linux /> },
  { label: 'Debian', logo: <Debian /> },
];

const ScrollingContainer = styled(Box)({
  width: '100%',
  background: 'transparent',
  padding: '10px 0',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
});

const ScrollWrapper = styled(Box)({
  display: 'inline-block',
  animation: 'scroll 140s linear infinite',
  whiteSpace: 'nowrap',
  '@keyframes scroll': {
    '0%': { transform: 'translateX(70%)' },
    '100%': { transform: 'translateX(-100%)' },
  },
});

const SkillChips = ({ animate }) => {
  if (animate) {
    return (
      <>
      <ScrollingContainer>
          <ScrollWrapper>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {skills.map((skill) => (
                <Chip
                  key={skill.label}
                  avatar={<Avatar sx={{ bgcolor: 'white' }}>{skill.logo}</Avatar>}
                  label={skill.label}
                  variant="outlined"
                  sx={{ bgcolor: '#f5f5f5', mx: 0.5 }}
                />
              ))}
              {skills.map((skill) => (
                <Chip
                  key={skill.label + '-duplicate'}
                  avatar={<Avatar sx={{ bgcolor: 'white' }}>{skill.logo}</Avatar>}
                  label={skill.label}
                  variant="outlined"
                  sx={{ bgcolor: '#f5f5f5', mx: 0.5 }}
                />
              ))}
            </Box>
          </ScrollWrapper>
        </ScrollingContainer>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          {skills.map((skill) => (
            <Grid item key={skill.label}>
              <Chip
                avatar={<Avatar sx={{ bgcolor: 'white' }}>{skill.logo}</Avatar>}
                label={skill.label}
                variant="outlined"
                sx={{ bgcolor: '#f5f5f5' }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
};

export default SkillChips;
