import React, { useState, Fragment } from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Link,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { debounce } from "lodash";
import MyMpHero from "./MyMpHero";

const TellMeAboutMyMP = () => {
  const [mpNameInput, setMpNameInput] = useState("");
  const [mpData, setMpData] = useState(null);
  const [interests, setInterests] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchMPOptions = debounce(async (input) => {
    if (!input) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `https://members-api.parliament.uk/api/Members/Search?Name=${input}&skip=0&take=20`
      );
      const mpItems = response.data.items;
      const currentMPs = mpItems.filter(
        (item) =>
          item.value.latestHouseMembership.membershipStatus &&
          item.value.latestHouseMembership.membershipStatus.statusIsActive ===
            true
      );
      const formattedOptions = currentMPs.map((item) => ({
        label: item.value.nameDisplayAs,
        id: item.value.id,
      }));
      setOptions(formattedOptions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching MP options:", error);
      setLoading(false);
    }
  }, 300); // Debounce with 300ms delay

  const handleInputChange = (event, value) => {
    setMpNameInput(value);
    fetchMPOptions(value);
  };

  const handleIdChange = async (event, value) => {
    if (!value) return;
    setLoading(true);
    setError("");
    try {
      const mpId = value.id;
      const mpName = value.label;

      console.log(`Fetching details for MP ID: ${mpId}, Name: ${mpName}`);

      const mpDetailResponse = await axios.get(
        `https://members-api.parliament.uk/api/Members/${mpId}`
      );
      console.log("MP Detail Response:", mpDetailResponse.data);
      const mpDetail = mpDetailResponse.data.value;

      const party = mpDetail.latestParty.name;
      const constituencyName = mpDetail.latestHouseMembership.membershipFrom;
      const gender = mpDetail.gender === "M" ? "He" : "She";
      const thumbnailUrl = mpDetail.thumbnailUrl;
      const firstElectDate = new Date(
        mpDetail.latestHouseMembership.membershipStartDate
      );
      const formattedFirstElectDate = firstElectDate.toLocaleDateString(
        "en-GB",
        { day: "2-digit", month: "long", year: "numeric" }
      );

      const bioResponse = await axios.get(
        `https://members-api.parliament.uk/api/Members/${mpId}/Biography`
      );
      const bio = bioResponse.data.value.governmentPosts;
      let ministerialPost = "";
      let formattedPostStartDate = "";
      let formattedPostEndDate = "";
      if (bio.length) {
        ministerialPost = bio[0].name;
        const postStartDate = new Date(bio[0].startDate);
        formattedPostStartDate = postStartDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
        if (bio[0].endDate) {
          const postEndDate = new Date(bio[0].endDate);
          formattedPostEndDate = postEndDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          });
        }
      }

      const interestsResponse = await axios.get(
        `https://members-api.parliament.uk/api/Members/${mpId}/RegisteredInterests`
      );
      const interests = interestsResponse.data.value;

      setMpData({
        mpId,
        mpName,
        party,
        constituencyName,
        gender,
        thumbnailUrl,
        formattedFirstElectDate,
        ministerialPost,
        formattedPostStartDate,
        formattedPostEndDate,
      });
      setInterests(interests);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching MP details:", error);
      setError("Failed to fetch MP data.");
      setLoading(false);
    }
  };

  return (
    <Container>
      <MyMpHero />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Autocomplete
            id="mp-search-combo"
            sx={{ width: "50%",  marginTop: 5 }}
            disableClearable
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            onInputChange={handleInputChange}
            onChange={handleIdChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Find my MP...."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
          </Box>
          {mpData && (
            <Card sx={{ marginTop: 2, padding: { xs: 1, md: 1 } }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CardMedia
                      component="img"
                      image={mpData.thumbnailUrl}
                      alt={mpData.mpName}
                      sx={{
                        objectFit: "cover",
                        borderRadius: "8px",
                        width: { xs: "100%", md: "auto" },
                        height: { xs: "auto", md: "225px" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="h5" component="div">
                      {mpData.mpName}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginTop: { xs: 2, md: 0 } }}
                    >
                      {mpData.gender} is the {mpData.party} Party representative
                      for {mpData.constituencyName}. {mpData.gender} has been an
                      MP since {mpData.formattedFirstElectDate}.
                      {mpData.ministerialPost && (
                        <>
                          {" "}
                          {mpData.gender} has been {mpData.ministerialPost}{" "}
                          since {mpData.formattedPostStartDate}.{" "}
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      {mpData && interests.length > 0 && (
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div" gutterBottom>
              Register of Interests
            </Typography>
            {interests.map((interest, index) => (
              <Card key={index} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {interest.name}
                  </Typography>
                  {interest.interests.map((i, idx) => (
                    <div key={idx}>
                      <Typography variant="body1" color="text.secondary">
                        {i.interest}
                      </Typography>
                      {i.childInterests.map((child, childIdx) => (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          key={childIdx}
                        >
                          {child.interest}
                        </Typography>
                      ))}
                      <Divider sx={{ marginY: 1 }} />
                    </div>
                  ))}
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default TellMeAboutMyMP;
