import React from 'react';
import { Avatar, Badge } from '@mui/material';
import { styled } from '@mui/system';

const PublicBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'yellow',
    color: 'black',
    fontSize: '0.75rem',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PublicAvatarBadge = ({ alt, src, isPublic, onClick, onMouseEnter, onMouseLeave }) => (
  <PublicBadge
    badgeContent="P"
    invisible={!isPublic}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <Avatar
      alt={alt}
      src={src}
      sx={{ width: 50, height: 50, cursor: 'pointer' }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  </PublicBadge>
);

export default PublicAvatarBadge;



