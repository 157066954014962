import { api } from '../../../services/api'

const NinetyNineApi = api.injectEndpoints({
  endpoints: (build) => ({
    get99UKEconomics: build.query({
        query: (projectName) => `${projectName}/uk-economic-news/`,
        providesTags: ['99Economics'],
      }),
      get99UKThinkTanks: build.query({
        query: (projectName) => `${projectName}/uk-think-tank-news/`,
        providesTags: ['99Economics'],
      }),
      get99InternationalNews: build.query({
        query: (projectName) => `${projectName}/international-news/`,
        providesTags: ['99Economics'],
      }),
  }),

  overrideExisting: false,
})

export const { useGet99InternationalNewsQuery, useGet99UKEconomicsQuery,
useGet99UKThinkTanksQuery } = NinetyNineApi