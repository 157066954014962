import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import { useLoginMutation, useLogoutMutation, useFetchCredentialsQuery } from "./LoginApi";
import { setCredentials, logout } from "../../features/auth/authSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [role, setRole] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [logoutMutation] = useLogoutMutation();
  const {  refetch } = useFetchCredentialsQuery();
  const user = useSelector((state) => state.auth.user);
  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("token"); // Adjust if needed
    await logoutMutation({ refresh: refreshToken }).unwrap();
    dispatch(logout());
    localStorage.removeItem("token");

    // Invalidate and refetch the credentials query
    console.log('Refetching credentials...');
    refetch(); // Call refetch to invalidate the query
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      // Clear any existing token and user data
      dispatch(logout());
      localStorage.removeItem("token");

      const user = await login({ email, password }).unwrap();
      dispatch(setCredentials({ token: user.access, user: user.user }));
      localStorage.setItem("token", user.access);

      setSuccessMessage("Login successful!");
      setShowSuccessModal(true);
      setRole(user.role)

      // Clear email and password fields
      setEmail("");
      setPassword("");

      // Refetch credentials after successful login
      console.log('Refetching credentials...');
      refetch();

      setTimeout(() => {
        setShowSuccessModal(false);
        navigate("/");
      }, 3000);
    } catch (err) {
      if (err.status && err.status >= 400 && err.status < 500) {
        setError(err.data.detail);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setError("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  console.log("user role:", role)
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderRadius: 2,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        position: "relative",
      }}
    >
      {user ? (
       <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>

       <Typography>
         <span style={{ color: "black", fontWeight: "bold" }}>User:</span>{" "}
         <span style={{ color: "black" }}>{user}</span>
       </Typography>
       {role === "admin" && (
      <Button
      variant="contained"
      color="primary"
      component={Link}
      to="/admin"
    >
      Admin
    </Button>
         )}
       <Button variant="contained" color="primary" onClick={handleLogout}>
         Logout
       </Button>
     </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
        </Box>
      )}

      <Modal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={showErrorModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" component="h2">
              Error
            </Typography>
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={showSuccessModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: '80%', // Responsive width
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
              textAlign: 'center' // Center text
            }}
          >
            <Typography variant="h6" component="h2">
              Success
            </Typography>
            <Typography variant="body2" color="success.main">
              {successMessage}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Login;
