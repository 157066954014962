import React, { useState } from 'react';
import { Box, useTheme, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { useFetchCurrentInvestmentsQuery } from './CurrentInvestmentsApi';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#FFCE56']; // Custom colors for the chart

const CurrentInvestmentPieChart = () => {
  const theme = useTheme();
  const projectName = 'investment';
  const { data: investments = [] } = useFetchCurrentInvestmentsQuery(projectName);

  // State to track selected book
  const [selectedBook, setSelectedBook] = useState('all');

  // Handler for dropdown selection
  const handleBookChange = (event) => {
    setSelectedBook(event.target.value);
  };

  // Filter investments based on the selected book
  const filteredInvestments = selectedBook === 'all'
    ? investments
    : investments.filter(investment => investment.book === selectedBook);

  // Aggregate data by ticker
  const aggregatedData = filteredInvestments.reduce((acc, investment) => {
    if (!acc[investment.ticker]) {
      acc[investment.ticker] = {
        ticker: investment.ticker,
        totalValue: 0,
        books: {},
      };
    }

    const value = investment.calculated_gbp_value;
    acc[investment.ticker].totalValue += value;
    if (!acc[investment.ticker].books[investment.book]) {
      acc[investment.ticker].books[investment.book] = {
        bookValue: 0,
        bookTotal: 0,
      };
    }
    acc[investment.ticker].books[investment.book].bookValue += value;

    return acc;
  }, {});

  // Calculate total portfolio value
  const totalPortfolioValue = Object.values(aggregatedData).reduce((acc, tickerData) => acc + tickerData.totalValue, 0);

  // Calculate the total value per book
  const totalBookValues = filteredInvestments.reduce((acc, investment) => {
    const book = investment.book;
    const value = investment.calculated_gbp_value;

    if (!acc[book]) {
      acc[book] = 0;
    }
    acc[book] += value;

    return acc;
  }, {});

  // Prepare data for the PieChart
  const pieData = Object.values(aggregatedData).map((tickerData) => {
    Object.keys(tickerData.books).forEach((book) => {
      tickerData.books[book].bookTotal = totalBookValues[book];
    });
    return {
      name: tickerData.ticker,
      value: tickerData.totalValue,
      books: tickerData.books,
    };
  });

  return (
    <Box sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FormControl variant="outlined" sx={{ mb: 1, minWidth: 200 }}>
        <InputLabel>Book</InputLabel>
        <Select
          value={selectedBook}
          onChange={handleBookChange}
          label="Book"
        >
          <MenuItem value="all">All Books</MenuItem>
          {Array.from(new Set(investments.map(investment => investment.book))).map(book => (
            <MenuItem key={book} value={book}>{book}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <PieChart width={600} height={535}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={pieData}
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
          label={({ name }) => name}  // Display the ticker as the label
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const tickerData = payload[0].payload;
              const tickerTotalPercentage = ((tickerData.value / totalPortfolioValue) * 100).toFixed(2);
              const bookEntries = Object.entries(tickerData.books);

              return (
                <div
                  className="custom-tooltip"
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    padding: '5px',
                    border: '1px solid #ccc'
                  }}
                >
                  <p>{`${tickerData.name}: ${tickerTotalPercentage}% of portfolio`}</p>
                  <ul>
                    {bookEntries.map(([book, { bookValue, bookTotal }]) => {
                      const bookPercentage = ((bookValue / tickerData.value) * 100).toFixed(2);
                      const totalBookPercentage = ((bookValue / bookTotal) * 100).toFixed(2);
                      return (
                        <li key={book}>
                          {`${book}: ${bookPercentage}% of ${tickerData.name}, ${totalBookPercentage}% of ${book} book`}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            }
            return null;
          }}
        />
      </PieChart>
    </Box>
  );
};

export default CurrentInvestmentPieChart;

