import React, { Fragment } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import EmbedComponent from "../../../components/Ninety-Nine/EmbedComponent";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import Recharts from "../../../components/Ninety-Nine/RechartsComponent";
import DataViewerIframe from "../../../components/Ninety-Nine/TestOECDChart";

const NinetyNineChartOfTheWeek = () => {
const chartOfTheWeek = [
    'https://ourworldindata.org/explorers/population-and-demography?facet=none&country=USA~JPN~GBR~FRA~FIN~DEU~GRC~ITA~NOR~ESP&Metric=Life+expectancy&Sex=Both+sexes&Age+group=At+birth&Projection+Scenario=None&hideControls=true&tab=chart',
    "https://data-viewer.oecd.org?chartId=8bae25fd-87ee-49a5-bba3-92fb1e1bc9f0",
    "https://ourworldindata.org/grapher/homelessness-rate-point-in-time-count?tab=chart"


]
console.log(chartOfTheWeek[0])



  return (
    <Fragment>
       <Box
            sx={{
              mb: 2,
              mt: 5,
            }}
          >
            <Typography variant="h4" gutterBottom>
              Chart of the Week
            </Typography>
            <Typography variant="h7" gutterBottom>
            </Typography>
          </Box>
        <EmbedComponent
          url={chartOfTheWeek[2]}
          width="100%"
          height="40vh"
        />
        {/* <Recharts /> */}
        {/* <DataViewerIframe/> */}
    </Fragment>
  );
};

export default NinetyNineChartOfTheWeek;