import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const isProduction = process.env.NODE_ENV === 'production';

const baseUrl = isProduction
  ? 'https://rqe5pmeazg.eu-west-2.awsapprunner.com'
  : 'http://127.0.0.1:8000/';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Content-Type', 'application/json');
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

