import React from "react";
import { Box, Typography, Container, Link } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import backgroundImage from "../../assets/images/unsplash1.png"; // Import your background image

const HeroSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        py: 2,
        px: 10,
      }}
    >
      {/* Semi-transparent overlay to make the background image less dark */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust the opacity here
          zIndex: 1,
        }}
      />
      <Link href="/" sx={{ position: "absolute", top: 16, left: 16, color: "white", zIndex: 2 }}>
        <HomeIcon sx={{ fontSize: 40 }} />
      </Link>
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h2" component="div" paragraph >
          Vince Gomez
        </Typography>
        <Typography variant="h5" color="inherit" paragraph >
          IT Contract Consultant Specialising in Financial Markets
        </Typography>
        <Typography variant="subtitle1" color="inherit" paragraph >
          With a deep-seated passion for programming and technology spanning
          over a decade, I bring a wealth of experience to the table. My career
          began with 20 years as a corporate bond trader in the City of London,
          where I honed my analytical skills and market expertise. From 2020 to
          2022, I served as the CEO and co-founder of {' '}
          <Link href="https://www.curvecue.vincegomez.com" target="_blank" rel="noopener" >
            CurveCue Data Analytics.
          </Link>
          {' '} You can see a CurveCue demo on YouTube {' '}
          <Link href="https://www.youtube.com/channel/UCPt7epI-eKIMKJhSmrGJ55w" target="_blank" rel="noopener">
            here.
          </Link>
        </Typography>
        <Typography variant="subtitle1" color="inherit" paragraph >
          I specialise in using React for front-end development and Python,
          particularly Django or Flask, for back-end development. As an AWS
          Certified Cloud Practitioner and Certified Solutions Architect
          Associate, I am equipped to advise on and implement robust AWS cloud
          hosting solution architectures.
        </Typography>
        <Typography variant="subtitle1" color="inherit" paragraph >
          If you would like to discuss potential projects,
          please call +44 7875 595 486 or email
          vincent_gomez@hotmail.com.{' '}
          <Link href="https://website-public-bucket.s3.eu-west-2.amazonaws.com/VG_CVT1.pdf" target="_blank" rel="noopener" sx={{  fontWeight: 'bold' }}>
            Click here to view my resume.
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default HeroSection;
