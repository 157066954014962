import { api } from '../../services/api'

const AdminApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDashboardChart: builder.mutation({
        query: (dataPayload) => ({
            url: `${dataPayload.project_name}/create-dashboard-chart/`,
            method: "POST",
          body: JSON.stringify(dataPayload),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      }),
      getDashboardCharts: builder.query({
        query: (projectName) => `${projectName}/get-books/`,
      }),
    }),
  });

  export const { useCreateDashboardChartMutation, useGetDashboardChartsQuery } = AdminApi;