import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Link } from '@mui/material';

const NewsList = ({ title, jsonString }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    try {
      // Parse the JSON string into an array
      const parsedArticles = JSON.parse(jsonString);
      setArticles(parsedArticles);
    } catch (error) {
      console.error('Failed to parse articles:', error);
      // Handle errors or invalid JSON here, perhaps setting an error state
    }
  }, [jsonString]);

  return (
    <Box sx={{ margin: 2, width: '100%' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {articles.map((article, index) => (
          <ListItem key={index} alignItems="flex-start">
            <ListItemText
              primary={<Link href={article.link} target="_blank" rel="noopener">{article.title}</Link>}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    {article.source} - Updated: {new Date(article.published).toLocaleDateString()}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NewsList;

