import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Container, Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InvestmentsImage from '../../assets/images/investments_top.png';
import Broker from '../../assets/images/ardent-resize.png';

// Define a sample component structure
const sampleComponents = [
  {
    title: 'Personal Investment Security Screener',
    description: "An ETF screener that I designed and built to manage my personal investments. It uses the algorithmic analysis of c.3600 funds traded on the London Stock Exchange. Instrument reference data, weekly and monthly volumes are extracted into a Postgres database directly from the LSE website and the algorithmic analysis is uploaded from a 3rd party vendor. Data is queried and transformed in a backend Django app and queried and displayed in a React frontend application using MaterialUI v5. Securities can be filtered according to their technical characteristics, including investment rating (Buy, Sell Hold), trade volumes, sector or geographic region. The user can also save portfolios and create watchlists. Demo available on request.",
    imageUrl: InvestmentsImage,
    path: '/sagequery'
  },
  {
    title: 'Financial Broker Integrated Intranet',
    description: 'Working closely with the various stakeholders in a financial brokerage, I designed, built and deployed on AWS a series components to help the sales, trading, risk management, and senior management functions analyze various aspects of firm performance. The CRM, order management and trade analysis systems helped sales and trading better understand what their customers were doing and where they could better target resources in order to drive revenues. The real time risk management component allowed risk managers to see dynamically updated positions and senior managers defined a series of KPI’s which they could view on a customizable dashboard. Architecture demo available on request.',
    imageUrl: Broker,
  },
];

const PrivateProjects = () => {
  const [enlargedImage, setEnlargedImage] = useState(null);
  const navigate = useNavigate();

  const handleImageClick = (imageUrl) => {
    setEnlargedImage(imageUrl);
  };

  const handleClose = () => {
    setEnlargedImage(null);
  };

  return (
    <Container>
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        {sampleComponents.map((component, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card onClick={() => navigate(component.path)} sx={{ cursor: 'pointer' }}>
              <CardMedia
                component="img"
                height="300px"
                image={component.imageUrl}
                alt={component.title}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigation on image click
                  handleImageClick(component.imageUrl);
                }}
                sx={{ transition: 'height 0.5s ease-in-out', // Add a transition effect
                objectFit: 'cover', // Ensures the image covers the area while maintaining aspect ratio
                width: '100%', // Ensure the width fits the container
                height: '300px' // Consistent height for the transition effect
                 }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {component.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {component.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={Boolean(enlargedImage)}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: { backgroundColor: 'transparent', boxShadow: 'none' },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {enlargedImage && (
            <img
              src={enlargedImage}
              alt="Enlarged"
              style={{ maxHeight: '100%', maxWidth: '100%', objectFit:'contain' }}
              onClick={handleClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default PrivateProjects;

