import * as React from "react";
import { Container, CssBaseline, Typography, Grid } from "@mui/material";
import HeroSection from "./HeroSection.jsx";
import Skills from "./Skills.jsx";
import Projects from "./Projects.jsx";
import HorizontalGraphic from "./HorizontalGraphic.jsx";

const HomePage = () => {
  return (
    <>
      <CssBaseline />
      <HeroSection />
      <Container>
        <Typography
          variant="h4"
          component="h2"
          sx={{ paddingTop: 4, paddingBottom: 2 }}
        >
          Offering
        </Typography>
        <Typography
          variant="h6"
          component="h2"
          sx={{ paddingTop: 1, paddingBottom: 4 }}
        >
          As a consultant in the financial services industry, I specialize in
          delivering tailored IT solutions that drive business efficiency and
          innovation.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Whether you require a custom CRM solution to enhance customer
          insights, a migration from disparate pricing and research spreadsheets
          to a robust database with a web-based visualization framework, or
          advanced data analytics and machine learning integrations, I am
          equipped to optimize your IT infrastructure and implement
          state-of-the-art technology solutions.
        </Typography>
      </Container>
      <Container>
        <HorizontalGraphic />
      </Container>

      <Container sx={{ paddingLeft: 8, paddingTop: 2 }}>
        <Typography variant="h4" component="h2" sx={{ paddingLeft: 1 }}>
          Projects
        </Typography>
      </Container>

      <Projects />
      <Container sx={{ paddingLeft: 8, paddingTop: 4 }}>
        <Typography variant="h4" component="h2" sx={{ paddingLeft: 1 }}>
          Technologies and Frameworks
        </Typography>
      </Container>
      <Container sx={{ paddingBottom: 10, paddingTop: 2, height: 100 }}>
        <Grid container spacing={2}>
          <Skills />
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
