import React, { Fragment } from "react";
import { Container, Box } from "@mui/material";
import AddDashboardChart from "./AddDashboardChart";
import DashboardChartsGrid from './ViewDashboardCharts';

const NinetyNineAdmin = () => {
  // Render the main component if data is loaded and no error
  return (
    <Fragment>
      <Container>
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          <AddDashboardChart />
          <DashboardChartsGrid />
        </Box>
      </Container>
    </Fragment>
  );
};

export default NinetyNineAdmin;
