import React from 'react';
import { Box, Typography, Grid, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NinetyNineLogo from '../../../../assets/images/99-Hero-Logo.png';

const NinetyNineHeroSection = ({ title, subtext }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/ninety-nine');
  };

return (
  <Box sx={{ backgroundColor: 'rgb(254,238,213)', p: 4, mb: 4 }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={2}>
        <CardMedia
          sx={{ height: 150, width: 150, objectFit: "cover", borderRadius: "50%" , cursor: 'pointer'}}
          component="img"
          image={NinetyNineLogo}
          alt="99%"
          onClick={handleLogoClick}
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h7" gutterBottom>
          {subtext}
        </Typography>
      </Grid>
    </Grid>
  </Box>
  )
};

export default NinetyNineHeroSection;

