import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CurrentInvestmentForm from "./components/CurrentInvestments/CurrentInvestmentForm";
import CurrentInvestmentsGrid from "./components/CurrentInvestments/CurrentInvestmentsGrid";
import CurrentInvestmentsPieChart from "./components/CurrentInvestments/CurrentInvestmentsChart";
import HomeBar from "../../../components/HomeBar";

const CurrentInvestmentsPage = ({ themeMode, toggleTheme }) => {
  return (
    <Box
      sx={{
        margin: 5,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HomeBar
            themeMode={themeMode}
            toggleTheme={toggleTheme}
            homeLink="/investment"
          />
        </Grid>
        {/* Form Row */}
        <Grid item xs={12} md={4}>
          <CurrentInvestmentForm />
        </Grid>
        {/* Typography for Current Investments with Border */}
        <Grid item xs={12}>
          <Box
            sx={{
              border: "1px solid #ccc", // Thin border
              padding: 1, // Padding inside the border
              textAlign: "center", // Center text inside the box
              borderRadius: 1, // Optional: adds slight rounding to the corners
              marginBottom: 2
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Current Investments
            </Typography>
          </Box>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <CurrentInvestmentsGrid />
          </Grid>
          <Grid item xs={12} md={5}>
            <CurrentInvestmentsPieChart />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CurrentInvestmentsPage;
