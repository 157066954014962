import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InvestmentsImage from '../../assets/images/investments.jpg'
import Broker from '../../assets/images/broker.jpg'
// Define a sample component structure
const sampleComponents = [
  {
    title: 'Investments',
    description: 'An LSE ETF screener that I use to identify investments for my pension and personal account trading.',
    imageUrl: InvestmentsImage,
    path: '/investments'
  },

  {
    title: 'Financial broker integrated intranet',
    description: 'I designed, built  and deployed an integrated CRM, order management and risk system for a bond broker.',
    imageUrl: Broker,
  },
];

const PrivateProjects = () => {
    const navigate = useNavigate();

    return (
      <Grid container spacing={2} sx={{ padding: 4 }}>
        {sampleComponents.map((component, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card onClick={() => navigate(component.path)} sx={{ cursor: 'pointer' }}>
              <CardMedia
                component="img"
                height="140"
                image={component.imageUrl}
                alt={component.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {component.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {component.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  export default PrivateProjects;
