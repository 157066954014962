import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";
import { styled } from "@mui/system";
import UKFlag from "../../../assets/images/Flag_of_the_United_Kingdom.png"; // Update the path accordingly

const HeroContainer = styled(Box)({
  position: "relative",
  backgroundImage: `url(${UKFlag})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "8px",
  color: "white",
  marginTop: "10px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ContentContainer = styled(Container)({
  position: "relative",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  borderRadius: "8px",
  textAlign: "center",
  color: "white",
  padding: "10px",
});

const ItalicTypography = styled(Typography)({
  fontStyle: "italic",
  color: "red",
  marginBottom: "10px",
});

const StyledTypography = styled(Typography)({
  marginBottom: "10px",
  color: "white",
  fontSize: "1.2rem",
});

const MyMpHero = () => {
  return (
    <HeroContainer>
      <ContentContainer>
        <Typography variant="h4" component="h1" gutterBottom>
          Tell me about my MP 🇬🇧
        </Typography>
        <StyledTypography>
          We live in a representative democracy in which we elect MPs to
          represent our interests in Parliament. That is what they are paid to
          do, and that is their duty.
        </StyledTypography>
        <StyledTypography>
          And most of them work hard to carry out that duty.
        </StyledTypography>
        <StyledTypography>
          But some of them are also paid by commercial and political bodies - in
          some cases they are paid very handsomely. And this causes a conflict
          of interest. When someone is being paid an amount which is comparable
          with, or even larger than, their salary, it is worth asking: whose
          interests are they really serving?
        </StyledTypography>
        <StyledTypography>
          This website makes it very easy for you to see who is funding your MP.
          It is based on the official Declarations of Members' Interests and is
          updated automatically, whenever they make a new declaration. As many
          are new, their records may not be up-to-date quite yet. But you can
          check over time to see if your MP is subject to any important
          conflicts of interest. All you need to do is to enter their name.
        </StyledTypography>
        <StyledTypography>
          And if you are concerned by what you see, please take a look at the
          99% Organisation and {" "}
          <Link href="https://99-percent.org/" target="_blank" rel="noopener">
            join us.
          </Link>
        </StyledTypography>
        <ItalicTypography variant="body2">
          Note: The data are based on MPs’ declarations – if they have
          inadvertently forgotten to declare that a Russian Oligarch bought them
          a yacht, for example, that will not be shown here.
        </ItalicTypography>
      </ContentContainer>
    </HeroContainer>
  );
};

export default MyMpHero;
