import React from 'react';
import { IconButton } from '@mui/material';
import Brightness4 from '@mui/icons-material/Brightness4';
import Brightness7 from '@mui/icons-material/Brightness7';
import PropTypes from 'prop-types';

const ThemeToggle = ({ themeMode, toggleTheme }) => {
  return (
    <IconButton onClick={toggleTheme} color="inherit"   >
      {themeMode === 'light' ? <Brightness4  sx={{ fontSize: 35 }}/> : <Brightness7 sx={{ fontSize: 35 }} />}
    </IconButton>
  );
};

ThemeToggle.propTypes = {
  themeMode: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
};

export default ThemeToggle;

