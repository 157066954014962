import * as React from 'react';
import { CssBaseline } from '@mui/material';
import NavigationBar from '../../components/NavBar/NavigationBar';
import HomeNavBar from '../../components/NavBar/HomeNavBar';
import PublicHero from './PublicHero'
import PublicProjects from './PublicProjects';


const PublicHomePage = () => {
  return (
    <>
      <CssBaseline />
      <PublicHero />
      <PublicProjects />
    </>
  );
};

export default PublicHomePage;