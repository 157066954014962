import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useFetchCurrentInvestmentsQuery } from './CurrentInvestmentsApi';

const projectName = 'investment'

const CurrentInvestmentsGrid = () => {
  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const theme = useTheme();

  // Fetch investments data
  const { data: investments = [], isLoading: investmentsLoading } = useFetchCurrentInvestmentsQuery(projectName);

  const columns = [
    { field: 'id', headerName: 'ID', width: 50, hide: true  },
    { field: 'ticker', headerName: 'Ticker', width: 75 },
    { field: 'technical_rating', headerName: 'Rating', width: 75 },
    {
      field: 'qty',
      headerName: 'Qty',
      width: 75,
      valueFormatter: (params) => {
        if (params === undefined || params === null) {
          return null; // Do not format if value is undefined or null
        }
        return params.toLocaleString(); // Adds comma separators
      },
    },
    {
      field: 'avg_px',
      headerName: 'Avg Px',
      width: 75,
      valueFormatter: (params) => {
        if (params === undefined || params === null) {
          return null; // Do not format if value is undefined or null
        }
        return params.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }); // Adds comma separators and ensures two decimal places
      },
    },

    {
      field: 'price',
      headerName: 'Last Price',
      width: 100,
      valueFormatter: (params) => {
        if (params === undefined || params === null) {
          return null; // Do not format if value is undefined or null
        }
        return params.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }); // Adds comma separators and ensures two decimal places
      },
    },
    {
      field: 'pct_chg',
      headerName: '% Chg',
      width: 75,
      valueFormatter: (params) => {
        if (params === undefined || params === null) {
          return ''; // Return empty string if value is undefined or null
        }
        // Format the value as a percentage
        return `${params.toFixed(1)}%`;
      },
      renderCell: (params) => {
        if (params === undefined || params === null) {
            return null; // Do not render anything if value is undefined or null
        }

        const textColor = params.value < 0
          ? 'red'
          : theme.palette.mode === 'dark'
            ? 'white'
            : 'black';

        return (
          <Typography
            variant="body2"
            style={{
              color: textColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            {`${params.value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%`}
          </Typography>
        );
      },
    },
    {
      field: 'calculated_gbp_value',
      headerName: 'GBP Value',
      width: 100,
      valueFormatter: (params) => {
        if (params.value !== null && params.value !== undefined) {
          return params.value.toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
        return '';
      },
      renderCell: (params) => {
        return (
          <Typography
          variant="body2"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
           }} // Red text if the value is negative
          >
            {params.value !== null && params.value !== undefined
              ? params.value.toLocaleString('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,

                })
              : ''}
          </Typography>
        );
      },
    },
    { field: 'trading_currency', headerName: 'Ccy', width: 75 },
    { field: 'book', headerName: 'Book', width: 150 },
  ];

  return (
    <Box sx={{marginLeft: 2}}>
      <Grid item xs={12} md={12}>
        <DataGridPremium
          style={{ height: '635px', width: '100%' }}
          rows={investments}
          columns={columns}
          loading={investmentsLoading}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={(rowData) => setSelectedInvestment(rowData.row)}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </Grid>
      </Box>
  );
};

export default CurrentInvestmentsGrid;

