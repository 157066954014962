import React from "react";
import {
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import PropTypes from "prop-types";

const FilterOptions = ({ value, onChange }) => {
  return (
    <Card sx={{ margin: 0, padding: 0, minWidth: "120px" }}>
      <CardContent
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          "&:last-child": { paddingBottom: 0 },
        }}
      >
        <FormControl>
          <RadioGroup
            aria-label="filter-options"
            name="radio-buttons-group"
            value={value}
            onChange={onChange}
            sx={{ flexDirection: "column", margin: 0, padding: 0 }}
          >
            <FormControlLabel
              value="filterAll"
              control={<Radio size="small" />}
              label="All"
              sx={{
                margin: 0,
                ".MuiFormControlLabel-label": { fontSize: "0.875rem" },
              }}
            />
            <FormControlLabel
              value="filterWatchlist"
              control={<Radio size="small" />}
              label="Watchlist"
              sx={{
                margin: 0,
                ".MuiFormControlLabel-label": { fontSize: "0.875rem" },
              }}
            />
            <FormControlLabel
              value="filterPortfolio"
              control={<Radio size="small" />}
              label="Portfolio"
              sx={{
                margin: 0,
                ".MuiFormControlLabel-label": { fontSize: "0.875rem" },
              }}
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};

FilterOptions.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterOptions;
