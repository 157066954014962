import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@mui/material';

const EmbedComponent = ({ url, width, height }) => {
  console.log('Rendering EmbedComponent with URL:', url);

  return (
    <Box
      sx={{
        width: width || '100%',
        height: height || '100vh',
        backgroundColor: 'lightgrey', // Added for debugging
      }}
    >
      <iframe
        src={url}
        href={url}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        allowFullScreen
        title="Embedded Content"
        loading="lazy"
      ></iframe>

       <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Typography variant="body2">
          If the iframe does not load, click{' '}
          <Link href={url} target="_blank" rel="noopener noreferrer">
            here
          </Link>{' '}
          to view the content directly.
        </Typography>
      </Box>
    </Box>
  );
};

EmbedComponent.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default EmbedComponent;


