import React, { useEffect } from 'react';
import { Box, Typography, Modal } from '@mui/material';

const DetailModal = ({ open, message, onClose }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="detail-modal-title"
      aria-describedby="detail-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: 4,
          padding: 3,
          textAlign: 'center',
        }}
      >
        <Typography id="detail-modal-description" variant="h6" component="h6" sx={{ mb: 2, color: '#333' }}>
          {message}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DetailModal;

