import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Alert as MuiAlert,
  useMediaQuery,
} from "@mui/material";
import {
  useAddWatchlistSecurityMutation,
  useDeleteWatchlistSecurityMutation,
} from "./TradingViewTopBarApi";

const ManageWatchlist = ({ projectName }) => {
  const [watchlistTickerId, setWatchlistTickerId] = useState("");
  const [addWatchlistSecurity] = useAddWatchlistSecurityMutation();
  const [deleteWatchlistSecurity] = useDeleteWatchlistSecurityMutation();
  const [watchlistAlertStatus, setWatchlistAlertStatus] = useState({
    open: false,
    type: "",
    message: "",
  });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleCreateWatchlistSecurity = async () => {
    if (!watchlistTickerId) {
      showWatchlistAlert("error", "Ticker field must not be blank.");
      return;
    }
    try {
      const dataPayload = {
        id: watchlistTickerId,
        project: projectName,
      };
      const response = await addWatchlistSecurity(dataPayload);
      console.log("response: ", response)
      if ("error" in response) {
        const errorMessage = response.error.data?.id?.[0] || response.error.data?.error || "An unknown error occured.";
        showWatchlistAlert("error", errorMessage);
      } else {
        showWatchlistAlert("success", "Ticker added to watchlist.");
      }
    } catch (error) {
      showWatchlistAlert("error", "Error creating entry");
    } finally {
      setWatchlistTickerId(""); // Clear the input field
    }
  };

  const handleDeleteWatchlistSecurity = async () => {
    if (!watchlistTickerId) {
      showWatchlistAlert("error", "Ticker field must not be blank.");
      return;
    }
    try {
      const dataPayload = {
        id: watchlistTickerId,
      };
      const response = await deleteWatchlistSecurity(dataPayload);
      if ("error" in response) {
        const errorMessage = response.error.data?.detail || "Something else went wrong.";
        showWatchlistAlert("error", errorMessage);
      } else {
        showWatchlistAlert("success", "Ticker deleted from watchlist.");
      }
    } catch (error) {
      showWatchlistAlert("error", "Error deleting entry");
    } finally {
      setWatchlistTickerId(""); // Clear the input field
    }
  };

  const showWatchlistAlert = (type, message) => {
    setWatchlistAlertStatus({ open: true, type, message });
    setTimeout(() => {
      setWatchlistAlertStatus({ open: false, type: "", message: "" });
    }, 3000);
  };

  useEffect(() => {
    if (watchlistAlertStatus.open) {
      const timer = setTimeout(() => {
        setWatchlistAlertStatus({ open: false, type: "", message: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [watchlistAlertStatus]);

  return (
    <Card sx={{ margin: 0, padding: 0, minWidth: "300px" }}>
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          Manage Watchlist
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            gap: 2,
            marginTop: 1,
          }}
        >
          <TextField
            size="small"
            label="Ticker ID"
            variant="outlined"
            value={watchlistTickerId}
            onChange={(e) => setWatchlistTickerId(e.target.value.toUpperCase())}
            fullWidth
            required
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              gap: 1,
            }}
          >
            <Button size="small" variant="outlined" onClick={handleCreateWatchlistSecurity}>
              Create
            </Button>
            <Button
              sx={{
                borderColor: "red",
                color: "red",
                "&:hover": {
                  borderColor: "darkred",
                  color: "darkred",
                },
              }}
              size="small"
              variant="outlined"
              onClick={handleDeleteWatchlistSecurity}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </CardContent>
      {watchlistAlertStatus.open && (
        <MuiAlert sx={{ mx: "auto", fontSize: 14 }} severity={watchlistAlertStatus.type}>
          {watchlistAlertStatus.message}
        </MuiAlert>
      )}
    </Card>
  );
};

export default ManageWatchlist;

