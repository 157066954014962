import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetInstrumentQuery,
  useLazyGetMonthlyQuery,
  useLazyGetWeeklyQuery,
  useLazyUpdateTradingViewQuery,
  useLazyGetNoMetadataQuery
} from "./TradingViewTopBarApi";
import S3Upload from "./S3Upload";
import PropTypes from "prop-types";
import ManageWatchlist from "./ManageWatchlist";
import FilterOptions from "./FilterOptions";
import LseFetchButtonGroup from "./LseFetchButtonGroup";


const TradingViewTopBar = ({ onFilterChange, themeMode, toggleTheme }) => {
  const navigate = useNavigate();
  const projectName = 'investment';
  const [instrumentTrigger] = useLazyGetInstrumentQuery();
  const [weeklyTrigger] = useLazyGetWeeklyQuery();
  const [monthlyTrigger] = useLazyGetMonthlyQuery();
  const [noMetaDataTrigger] = useLazyGetNoMetadataQuery();
  const [tradingviewTrigger] = useLazyUpdateTradingViewQuery();
  const [value, setValue] = useState("filterAll");
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [isLoading, setLoading] = useState(false);

  const fetchData = async (trigger) => {
    try {
      setLoading(true);
      const response = await trigger(projectName);
      setLoading(false);
      setMsgOpen(true);
      if (response.data) {
        setMsgAlert(response.data);
      } else {
        setMsgAlert({
          open: true,
          type: "error",
          message: response.error.error,
        });
      }
    } catch (err) {
      setLoading(false);
      setMsgOpen(true);
      setMsgAlert({
        open: true,
        type: "error",
        message: err.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMsgClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsgOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    onFilterChange(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          mx: "auto",
          textAlign: "center",
          marginBottom: 1,
        }}
      >

        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <ManageWatchlist projectName={projectName} />
          <FilterOptions value={value} onChange={handleChange} />
          <S3Upload />
          <LseFetchButtonGroup
            fetchData={fetchData}
            tradingviewTrigger={tradingviewTrigger}
            instrumentTrigger={instrumentTrigger}
            noMetaDataTrigger={noMetaDataTrigger}
            weeklyTrigger={weeklyTrigger}
            monthlyTrigger={monthlyTrigger}
            isLoading={isLoading}
            msgOpen={msgOpen}
            msgAlert={msgAlert}
            handleMsgClose={handleMsgClose}
          />
                 <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/current-investments')}
          >
            Go to Current Investments
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

TradingViewTopBar.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
};

export default TradingViewTopBar;

