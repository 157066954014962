import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useLazyGetPresignedUrlQuery } from "./TradingViewTopBarApi";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const S3Upload = () => {
  const fileRef = useRef(null); // Use ref to store the file
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("text.secondary");
  const [
    triggerGetPresignedUrl,
    { data: presignedData, error: presignedError },
  ] = useLazyGetPresignedUrlQuery();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    fileRef.current = selectedFile; // Update ref with the selected file
    setMessage("");
  };

  const handleUpload = async () => {
    if (!fileRef.current) {
      setAlertSeverity("error");
      setAlertMessage("Please select a file to upload.");
      setAlertOpen(true);
      return;
    }

    setUploading(true);
    setMessage("");

    // Trigger the lazy query to get the pre-signed URL
    triggerGetPresignedUrl({
      filename: fileRef.current.name,
      filetype: fileRef.current.type,
    });
  };

  useEffect(() => {
    const uploadFile = async () => {
      if (presignedData && fileRef.current) {
        try {
          const { url, fields } = presignedData;

          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", fileRef.current);

          const response = await axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.status === 204) {
            setAlertSeverity("success");
            setAlertMessage("File uploaded successfully!");
            setMessage("File successfully uploaded."); // Set success message
            setMessageColor("green"); // Set message color to green for success
          } else {
            setAlertSeverity("error");
            setAlertMessage("File upload failed.");
            setMessage("File upload failed.");
            setMessageColor("red"); // Set message color to red for error
          }

          setAlertOpen(true);
          fileRef.current = null; // Clear the file ref
          document.getElementById("file-input").value = ""; // Clear the file input field
        } catch (uploadError) {
          setAlertSeverity("error");
          setAlertMessage("File upload failed.");
          setAlertOpen(true);
          setMessage("File upload failed.");
          setMessageColor("red"); // Set message color to red for error
          console.error("File upload error:", uploadError);
        } finally {
          setUploading(false);
        }
      }
    };

    uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presignedData]);

  useEffect(() => {
    let timer;
    if (message) {
      timer = setTimeout(() => {
        setMessage("");
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [message]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  // Automatically close the alert after 3 seconds
  useEffect(() => {
    let timer;
    if (alertOpen) {
      timer = setTimeout(() => {
        setAlertOpen(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [alertOpen]);

  return (
    <Card sx={{ margin: 0, padding: 0, minWidth: "300px" }}>
      <CardContent>
    <Box
      sx={{
        maxWidth: "350px",
        width: "100%",
        paddingTop: 2,
        paddingRight: 1,
        paddingLeft: 1,
        border: "1px solid #d3d3d3",
      }}
    >
      <Typography sx={{ fontSize: 16 }} gutterBottom color="text.secondary">
        Upload TradingView to S3
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <TextField
          size="small"
          id="file-input"
          type="file"
          onChange={handleFileChange}
        />
        {uploading && !presignedError ? (
          <CircularProgress sx={{ marginLeft: 1 }} />
        ) : (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleUpload}
            sx={{ marginLeft: 1 }}
          >
            Upload
          </Button>
        )}
      </Box>
      {presignedError && (
        <Typography
          variant="body1"
          sx={{ color: "red", marginBottom: 2 }}
        >
          {presignedError.originalStatus} Error: {presignedError.data}
        </Typography>
      )}
      {message && (
        <Typography
          variant="body1"
          sx={{ color: messageColor, marginBottom: 2 }}
        >
          {message}
        </Typography>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <MuiAlert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
    </CardContent>
    </Card>
  );
};

export default S3Upload;
