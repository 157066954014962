import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const ApiError = ({ error }) => {
  const errorMessage = error.data || "An unknown error occurred";

  return (
    <Box sx={{ padding: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="error">
        {errorMessage}
      </Typography>
    </Box>
  );
};

ApiError.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.string,
    originalStatus: PropTypes.number,
    data: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

export default ApiError;
