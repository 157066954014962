import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import backgroundImage from '../../assets/images/hero.jpg'; // Import your background image

const HeroSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        py: 8, // padding top and bottom
        my: 4, // margin top and bottom
      }}
    >
      <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
        Public Projects
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Here are a selection of personal and professional projects that I have been working on using docker, Django,
        React and Postgres.
        </Typography>
        {/* Additional content goes here */}
      </Container>
    </Box>
  );
};

export default HeroSection;