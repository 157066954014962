import React, { Fragment } from "react";
import { Chip, Container, Box, Stack, Typography } from "@mui/material";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import NinetyNineChartOfTheWeek from "./NinetyNineChartOfTheWeek";
import image from "../../../assets/images/G7-GDP-per-capita.png";


const NinetyNineNewsDashboard = () => {
  const title = "99% Datasite";
  const subtext = `WIP  - Need some text here explaining data and datasets available.
`;

const handleClick = (url) => {
  window.open(url, "_blank");
};

  // Render the main component if data is loaded and no error
  return (
    <Fragment>
      <NinetyNineHeroSection title={title} subtext={subtext} />
      <Container>
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Chip
              label="News"
              component="a"
              href="/ninety-nine/news"
              clickable
            />
            <Chip
              label="UK Economics Dashboard"
              component="a"
              href="/ninety-nine/uk-econ"
              clickable
            />
            <Chip
              label="OECD Economic Wellbeing Dashboard"
              component="a"
              href="/ninety-nine/oecd-economic-wellbeing"
              clickable
            />
             <Chip
              label="OECD Housing"
              component="a"
              href="/ninety-nine/oecd-housing"
              clickable
            />
             <Chip
              label="OECD Governments At A Glance"
              onClick={() => handleClick("https://www.oecd.org/en/data/dashboards/government-at-a-glance-2023.html")}
              clickable
            />
            <Chip
              label="Another interesting Page - Healthcare - TBD?"
              component="a"
              href="#"
              clickable
            />
          </Stack>


          <NinetyNineChartOfTheWeek />

        </Box>
      </Container>
    </Fragment>
  );
};

export default NinetyNineNewsDashboard;
