import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store';
import HomePage from './pages/HomePage/HomePage';
import SageQuery from './pages/SageQuery/SageQueryHomePage';
import ErrorPage from './pages/Error/Error';
import TradingView from './pages/PrivateProjects/TradingView/TradingView';
import PrivateHomePage from './pages/PrivateProjects/PrivateHome';
import PublicHomePage from './pages/PublicProjects/PublicHome';
import NinetyNineHomePage from './pages/PublicProjects/NinetyNine/NinetyNineHome';
import NinetyNineNews from './pages/PublicProjects/NinetyNine/NinetyNineNews';
import MyMpPage from './pages/PublicProjects/MyMP/MyMP';
import TestPage from './pages/Test/TestPage';
import AdminPage from './pages/Admin/AdminPage';
import NinetyNineUKEconDashboard from './pages/PublicProjects/NinetyNine/NinetyNineUKEconDashboard';
import NinetyNineOECDDashboard from './pages/PublicProjects/NinetyNine/NinetyNineOECDWellbeing';
import NinetyNineOECDHousing from './pages/PublicProjects/NinetyNine/NinetyNineOECDHousing';
import CurrentInvestmentsPage from './pages/PrivateProjects/TradingView/CurrentInvestment';
import NinetyNineAdmin from './pages/Admin/NinetyNine/NinetyNineAdmin';

function App() {
  const [themeMode, setThemeMode] = useState('light');

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: '#1976d2',
          },
          secondary: {
            main: '#ff4081',
          },
        },
      }),
    [themeMode]
  );

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} errorElement={<ErrorPage />} />
            <Route path="/sagequery" element={<SageQuery />} errorElement={<ErrorPage />} />
            <Route path="/private" element={<PrivateHomePage />} errorElement={<ErrorPage />} />
            <Route path="/public" element={<PublicHomePage />} errorElement={<ErrorPage />} />
            <Route path="/ninety-nine" element={<NinetyNineHomePage />} />
            <Route path="/ninety-nine/news" element={<NinetyNineNews />} />
            <Route path="/ninety-nine/uk-econ" element={<NinetyNineUKEconDashboard />} />
            <Route path="/ninety-nine/oecd-economic-wellbeing" element={<NinetyNineOECDDashboard />} />
            <Route path="/ninety-nine/oecd-housing" element={<NinetyNineOECDHousing />} />
            <Route path="/my-mp" element={<MyMpPage />} />
            <Route
              path="/investment"
              element={<TradingView themeMode={themeMode} toggleTheme={toggleTheme} />}
            />
              <Route path="/current-investments" element={<CurrentInvestmentsPage themeMode={themeMode} toggleTheme={toggleTheme}  />} />
            <Route path="/test" element={<TestPage />} errorElement={<ErrorPage />} />
            <Route path="/admin" element={<AdminPage />} errorElement={<ErrorPage />} />
            <Route path="/admin-99" element={<NinetyNineAdmin />} errorElement={<ErrorPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
