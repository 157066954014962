import { Box, Card, CardContent } from '@mui/material';
import HomeLink from './HomeLink'; // Adjust the import path as needed
import ThemeToggle from './ThemeToggle'; // Adjust the import path as needed
import PropTypes from 'prop-types';

const HomeBar = ({ themeMode, toggleTheme, homeLink }) => {
  return (
    <Card sx={{ marginBottom: 2, padding: 0, width: '100%', height: '50px'  }}>
      <CardContent sx={{ padding: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <HomeLink themeMode={themeMode} homeLink={homeLink} />
          <ThemeToggle themeMode={themeMode} toggleTheme={toggleTheme} />
        </Box>
      </CardContent>
    </Card>
  );
};

HomeBar.propTypes = {
  themeMode: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  homeLink: PropTypes.string.isRequired,
};

export default HomeBar;


