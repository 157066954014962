import React, { Fragment } from "react";
import { Box, Typography, Grid, CardMedia, Container } from "@mui/material";
import NewsList from "./components/NewsList";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import {
  useGet99InternationalNewsQuery,
  useGet99UKEconomicsQuery,
  useGet99UKThinkTanksQuery,
} from "./NinetyNineApi";



const NewsListContainer = ({ useNewsQuery, title }) => {
  const { data: articles, error, isLoading } = useNewsQuery('ninety-nine');

  if (isLoading)
    return (
      <Box>
        <p>Loading {title}...</p>
      </Box>
    );
  if (error)
    return (
      <Box>
        <p>Error loading {title}</p>
      </Box>
    );
  if (!articles)
    return (
      <Box>
        {" "}
        <p>No data available for {title}</p>
      </Box>
    );

  const jsonString = JSON.stringify(
    articles.map((article) => ({
      title: article.title,
      link: article.link,
      published: article.published,
      source: article.source, // Assuming this is how your data structure is defined
    }))
  );

  return <NewsList title={title} jsonString={jsonString} />;
};
const title = "99% News"
const subtext = `We have a mix of RSS feeds (The UK Economic News feed is an RSS feed from
                 the ONS which is quick to load) as well as some custom scraping scripts
                 . The Think Tank News is 2 webscrapers at the moment. One which
                  scrapes the latest from the Nuffield Trust website and another from the Smart Thinking
                  website (https://smartthinking.org.uk/reports/). Sources can be added modularly. Need to
                  work on tagging and persisting to db.
                  `


const NinetyNineNewsDashboard = () => {
  // Render the main component if data is loaded and no error
  return (
    <Fragment>
       <NinetyNineHeroSection title={title} subtext={subtext}/>
       <Container>
      <Box >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <NewsListContainer
              useNewsQuery={useGet99InternationalNewsQuery}
              title="International News"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NewsListContainer
              useNewsQuery={useGet99UKEconomicsQuery}
              title="UK Economic News"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NewsListContainer
              useNewsQuery={useGet99UKThinkTanksQuery}
              title="Think Tank News"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
    </Fragment>
  );
};

export default NinetyNineNewsDashboard;
