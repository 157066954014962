import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useGetDashboardChartsQuery } from '../AdminApi';
import { Box, Typography } from '@mui/material';

const DashboardChartDataGrid = () => {
  const { data, error, isLoading } = useGetDashboardChartsQuery('ninety-nine');

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error loading charts: {error.message}</Typography>;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'subtitle', headerName: 'Subtitle', width: 200 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'chart_url', headerName: 'Chart URL', width: 250 },
    { field: 'source_url', headerName: 'Source URL', width: 250 },
    { field: 'source', headerName: 'Source', width: 150 },
    { field: 'created_at', headerName: 'Created At', width: 200 },
  ];

  const rows = data.map((chart) => ({
    id: chart.id,
    title: chart.title,
    subtitle: chart.subtitle,
    description: chart.description,
    chart_url: chart.chart_url,
    source_url: chart.source_url,
    source: chart.source,
    created_at: new Date(chart.created_at).toLocaleString(),  // Format as needed
  }));

console.log('data: ', data)

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
      />
    </Box>
  );
};

export default DashboardChartDataGrid;
