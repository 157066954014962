import React, { useRef, useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useCreateDashboardChartMutation } from '../AdminApi';
import AlertMessage from './AlertMessage';

const DashboardChartForm = () => {
  const projectName = 'ninety-nine';
  const [createDashboardChart] = useCreateDashboardChartMutation();
  const [alertData, setAlertData] = useState(null);  // State to manage alert visibility and content

  // Using useRef to hold form data without triggering re-renders
  const formDataRef = useRef({
    title: '',
    subtitle: '',
    description: '',
    json_data: '',
    chart_url: '',
    source_url: '',
    source: '',
  });

  const handleChange = (e) => {
    formDataRef.current[e.target.name] = e.target.value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataPayload = {
        ...formDataRef.current,
        project_name: projectName,
      };
      console.log("Data to send to backend: ", dataPayload);
      const response = await createDashboardChart(dataPayload).unwrap();
      console.log('Server response:', response);

      // Trigger success alert
      setAlertData({
        status_txt: 'success',
        message: 'Chart created successfully!',
        open: true,
      });

    } catch (err) {
      console.error('Failed to create the chart:', err);

      // Trigger error alert
      setAlertData({
        status_txt: 'error',
        message: err.data?.message || 'An error occurred. Please try again.',
        open: true,
      });
    }
  };

  const handleCloseAlert = () => {
    setAlertData(null);  // Reset alert data after it closes
  };

  return (
    <Box component="form" onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        mx: 'auto',
        mt: 4,
        padding: '10px',
        border: '1px solid',
        borderColor: 'grey.400',
        borderRadius: 2,
      }}>
      <Typography variant="h6" mb={2}>
        Create Dashboard Chart
      </Typography>
      <TextField
        fullWidth
        label="Title"
        name="title"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Subtitle"
        name="subtitle"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Description"
        name="description"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        multiline
        rows={4}
        required
      />
      <TextField
        fullWidth
        label="JSON Data"
        name="json_data"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Chart URL"
        name="chart_url"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Source URL"
        name="source_url"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Source"
        name="source"
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Create Chart
      </Button>

      {/* Render AlertMessage underneath the Button */}
      {alertData && (
        <Box sx={{ mt: 2 }}>
          <AlertMessage
            status_txt={alertData.status_txt}
            message={alertData.message}
            open={alertData.open}
            onClose={handleCloseAlert}
          />
        </Box>
      )}
    </Box>
  );
};

export default DashboardChartForm;
