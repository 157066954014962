import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useGetTradingViewQuery } from "../TradingView/components/TradingViewTopBar/TradingViewTopBarApi";
import CircularProgress from "@mui/material/CircularProgress";
import TradingViewTopBar from "./components/TradingViewTopBar/TradingViewTopBar";
import HomeBar from "../../../components/HomeBar";
import ApiError from "../../../components/ApiError";


const columns = [
  { field: "id", headerName: "Ticker", width: 60 },
  {
    field: "description",
    headerName: "Description",
    width: 500,
    editable: false,
    sortable: true,
  },
  {
    field: "on_watchlist",
    headerName: "On Watchist",
    width: 100,
    editable: false,
    sortable: true,
  },
  {
    field: "in_portfolio",
    headerName: "In Portfolio",
    width: 100,
    editable: false,
    sortable: true,
  },
  {
    field: "technical_rating",
    headerName: "Rating",
    width: 100,
    editable: false,
    sortable: true,
  },
  {
    field: "asset_class",
    headerName: "Asset Class",
    width: 100,
    editable: false,
    sortable: true,
  },
  {
    field: "region",
    headerName: "Region",
    width: 120,
    editable: false,
    sortable: true,
  },

  {
    field: "country",
    headerName: "Country",
    width: 120,
    editable: false,
    sortable: true,
  },
  {
    field: "objective",
    headerName: "Objective",
    width: 200,
    editable: false,
    sortable: true,
  },

  {
    field: "trading_currency",
    headerName: "Ccy",
    width: 60,
    editable: false,
    sortable: false,
  },
  {
    field: "hedge_ccy",
    headerName: "Hdg Ccy",
    width: 80,
    editable: false,
    sortable: true,
  },
  {
    field: "avg_spread",
    headerName: "Spd",
    editable: false,
    sortable: true,
    type: "number",
    width: 60,
  },
  {
    field: "turnover_weekly",
    headerName: "1w Volume",
    type: "number",
    editable: false,
    sortable: true,
    width: 100,
  },
  {
    field: "num_trades_weekly",
    headerName: "1w # Trades",
    type: "number",
    align: "center",
    editable: false,
    sortable: false,
    width: 100,
  },
  {
    field: "avg_trade_size_weekly",
    headerName: "1w Avg Sz",
    editable: false,
    sortable: true,
    type: "number",
    width: 100,
  },

  {
    field: "turnover_monthly",
    headerName: "1m Volume",
    editable: false,
    sortable: true,
    type: "number",
  },
  {
    field: "num_trades_monthly",
    headerName: "1m # Trades",
    editable: false,
    sortable: true,
    type: "number",
    align: "center",
    width: 100,
  },
  {
    field: "avg_trade_size_monthly",
    headerName: "1m Avg Sz",
    editable: false,
    sortable: true,
    type: "number",
    width: 100,
  },
  {
    field: "perf_weekly",
    headerName: "1w %",
    width: 80,
    type: "number",
    editable: false,
    sortable: true,
  },
  {
    field: "perf_monthly",
    headerName: "1m %",
    editable: false,
    sortable: false,
    type: "number",
    width: 80,
  },
  {
    field: "perf_6m",
    headerName: "6m %",
    editable: false,
    sortable: true,
    type: "number",
    align: "center",
    width: 80,
  },
  {
    field: "perf_ytd",
    headerName: "YTD %",
    editable: false,
    sortable: true,
    type: "number",
    width: 80,
  },
  {
    field: "vol_1w",
    headerName: "1w Vol",
    editable: false,
    sortable: true,
    type: "number",
    width: 80,
  },
  {
    field: "vol_1m",
    headerName: "1M Vol",
    editable: false,
    sortable: true,
    type: "number",
    width: 80,
  },
];

export default function TradingView({ themeMode, toggleTheme }) {
  const [filter, setFilter] = useState("all");
  const { data, error, isLoading } = useGetTradingViewQuery('investment');
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (data) {
      setDataList(data);
    }
  }, [data]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredData = useMemo(() => {
    if (filter === "filterWatchlist") {
      return dataList.filter((item) => item.on_watchlist);
    } else if (filter === "filterPortfolio") {
      return dataList.filter((item) => item.in_portfolio);
    }
    return dataList;
  }, [dataList, filter]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ApiError error={error} />;
  }

  return (
    <Box
      sx={{
        padding: 1,
        width: "100%",
        "& .negative": {
          color: "#FF0000",
          fontWeight: "bold",
        },
      }}
    >
      <Box sx={{ height: "82vh", width: "100%", padding: 1 }}>
      <HomeBar themeMode={themeMode} toggleTheme={toggleTheme} homeLink='/' />
        <TradingViewTopBar onFilterChange={handleFilterChange} themeMode={themeMode} toggleTheme={toggleTheme} />
        <DataGridPremium
          columns={columns}
          rows={filteredData}
          sx={{
            "& .MuiDataGrid-row": {
              fontSize: "0.7rem",
            },
          }}
          getCellClassName={(params) => {
            if (params.value < 0) {
              return "negative";
            }
          }}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
}
