import * as React from 'react';
import { CssBaseline } from '@mui/material';
import PrivateHero from './PrivateHero'
import PrivateProjects from './PrivateProjects';


const PrivateHomePage = () => {
  return (
    <>
      <CssBaseline />
      <PrivateHero />
      <PrivateProjects />
    </>
  );
};

export default PrivateHomePage;