import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUpdateCurrentInvestmentMutation, useFetchBooksQuery } from './CurrentInvestmentsApi';

const CurrentInvestmentForm = () => {
  const projectName = "investment";
  const [investment, setInvestment] = useState({
    qty: '',
    ticker: '',
    transaction_px: '',
    book: '',
  });
  const [transactionType, setTransactionType] = useState(''); // State for Buy/Sell selection
  const [alertMessage, setAlertMessage] = useState(''); // State for alert message
  const [alertSeverity, setAlertSeverity] = useState('info'); // State for alert severity
  const [msgOpen, setMsgOpen] = useState(false); // State for controlling Snackbar visibility

  const [updateInvestment] = useUpdateCurrentInvestmentMutation();
  const { data: books = [], isLoading: booksLoading } = useFetchBooksQuery(projectName);

  const handleChange = (e) => {
    setInvestment({ ...investment, [e.target.name]: e.target.value });
  };

  const handleTransactionTypeChange = (e) => {
    setTransactionType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlertMessage(''); // Clear previous alert message

    // Ensure transaction type is selected
    if (!transactionType) {
      setAlertMessage('Please select a transaction type (Buy or Sell).');
      setAlertSeverity('error');
      setMsgOpen(true);
      return;
    }

    const adjustedInvestment = {
      ...investment,
      qty: transactionType === 'Sell' ? -Math.abs(investment.qty) : Math.abs(investment.qty),
      ticker: investment.ticker.toUpperCase(),
    };

    try {
      const response = await updateInvestment({ projectName, investment: adjustedInvestment });
      console.log("Investment Rsponse: ", response)
      if ('error' in response) {
        setAlertMessage(response.error.data.error || 'Something else went wrong.');
        setAlertSeverity('error');
      } else {
        setAlertMessage(response.data.message || 'Something else went wrong.');
        setAlertSeverity('success');
      }
    } catch (error) {
      console.error('Failed to update investment:', error);
      setAlertMessage('An unexpected error occurred.'); // Fallback error message
      setAlertSeverity('error');
    } finally {
      setMsgOpen(true);
      // Reset Form
      setInvestment({
        qty: '',
        ticker: '',
        transaction_px: '',
        book: '',
      })
      setTransactionType('')
    }
  };

  // Close the alert after 3 seconds
  useEffect(() => {
    if (msgOpen) {
      const timer = setTimeout(() => setMsgOpen(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [msgOpen]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Insert Investment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSubmit}>
              <FormControl component="fieldset" margin="normal" required>
                <FormLabel component="legend">Transaction Type</FormLabel>
                <RadioGroup
                  aria-label="transaction-type"
                  name="transaction-type"
                  value={transactionType}
                  onChange={handleTransactionTypeChange}
                  row
                >
                  <FormControlLabel value="Buy" control={<Radio />} label="Buy" />
                  <FormControlLabel value="Sell" control={<Radio />} label="Sell" />
                </RadioGroup>
              </FormControl>
              <TextField
                label="Quantity"
                name="qty"
                value={investment.qty}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Ticker"
                name="ticker"
                value={investment.ticker}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Transaction Price"
                name="transaction_px"
                value={investment.transaction_px}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Book</InputLabel>
                <Select
                  name="book"
                  value={investment.book}
                  onChange={handleChange}
                  required
                >
                  {booksLoading ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    books.map((book) => (
                      <MenuItem key={book.name} value={book.name}>
                        {book.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <Button type="submit" variant="contained" color="primary" sx={{marginTop: 1}}>
                Update Investment
              </Button>
            </form>
          </AccordionDetails>
        </Accordion>
        <Snackbar open={msgOpen} onClose={() => setMsgOpen(false)} autoHideDuration={3000}>
          <Alert onClose={() => setMsgOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default CurrentInvestmentForm;
