import React from 'react';
import { Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PropTypes from 'prop-types';

const HomeLink = ({ themeMode, homeLink }) => {
  return (
    <Link
      href={homeLink}
      sx={{
        color: themeMode === 'dark' ? 'white' : 'black',
      }}
    >
      <HomeIcon sx={{ fontSize: 40 }} />
    </Link>
  );
};

HomeLink.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default HomeLink;

