import { api } from '../../services/api';

const LoginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'core/login/',
        method: 'POST',
        body: credentials,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    fetchCredentials: builder.query({
      query: () => {
        const token = localStorage.getItem('token');
        return {
          url: 'core/credentials/',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { 'Authorization': `Bearer ${token}` }), // Only add the header if token exists
          },
        };
      },
    }),
    logout: builder.mutation({
      query: (refreshToken) => ({
        url: 'core/logout/',
        method: 'POST',
        body: { refresh: refreshToken },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useFetchCredentialsQuery,
  useLogoutMutation,
} = LoginApi;
