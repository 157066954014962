import { api } from "../../../../../services/api";

const TradingViewTopBarApi = api.injectEndpoints({
  tagTypes: ['Tradingview', 'Investment'],
  endpoints: (build) => ({
    getTradingView: build.query({
      query: (projectName) => ({
        url: `${projectName}/tradingview/`,
        method: 'GET',
        headers: {
          'Project-Name': projectName ,
        },
      }),
      providesTags: ["Tradingview"],
    }),
    getInstrument: build.query({
      query: (projectName) => ({
        url: `${projectName}/update-instruments/`,
        method: 'GET',
        headers: {
          'Project-Name': projectName ,
        },
      }),
      invalidatesTags: ["Tradingview"],
    }),
    getMonthly: build.query({
      query: (projectName) => ({
        url: `${projectName}/update-monthly/`,
        method: 'GET',
        headers: {
          'Project-Name': projectName ,
        },
      }),
      invalidatesTags: ["Tradingview"],
    }),
    getWeekly: build.query({
      query: (projectName) => ({
        url: `${projectName}/update-weekly/`,
        method: 'GET',
        headers: {
          'Project-Name': projectName ,
        },
      }),
      invalidatesTags: ["Tradingview"],
    }),
    updateTradingView: build.query({
      query: (projectName) => ({
        url: `${projectName}/update-tradingview/`,
        method: 'GET',
        headers: {
          'Project-Name': projectName,
         },
      }),
      invalidatesTags: ["Tradingview"],
    }),
    getNoMetadata: build.query({
      query: (projectName) => ({
        url: `${projectName}/download-no-metadata/`,
        method: 'GET',
        headers: {
          'Project-Name': projectName ,
        },
      }),
    }),
    addWatchlistSecurity: build.mutation({
      query: (body) => ({
        url: "investment/add-to-watchlist/",
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          'Project-Name': 'investment' ,
        },
      }),
      invalidatesTags: ["Tradingview"],
    }),
    deleteWatchlistSecurity: build.mutation({
      query: ({ id, data }) => ({
        url: `investment/delete-from-watchlist/${id}/`,
        method: "DELETE",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          'Project-Name': 'investment' ,
        },
      }),
      invalidatesTags: ["Tradingview"],
      onError: (error) => {
        console.error("deletefrom Watchlist mutation error:", error);
      },
    }),
    getTemporaryCredentials: build.query({
      query: () => "investment/get-temporary-credentials/",
    }),
    getPresignedUrl: build.query({
      query: ({ filename, filetype }) => ({
        url: 'investment/get-presigned-url/',
        method: 'GET',
        params: { filename, filetype },
        headers: {
          'Project-Name': 'investment',
         },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTradingViewQuery,
  useLazyGetInstrumentQuery,
  useAddWatchlistSecurityMutation,
  useDeleteWatchlistSecurityMutation,
  useLazyGetWeeklyQuery,
  useLazyUpdateTradingViewQuery,
  useLazyGetMonthlyQuery,
  useLazyGetNoMetadataQuery,
  useGetTemporaryCredentialsQuery,
  useLazyGetPresignedUrlQuery,
} = TradingViewTopBarApi;
